import React from 'react';

import clsx from 'clsx';
import { Typography } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import PartnershipSwiper from 'components/main-page/partnership-programmes/partnership-swiper/partnership-swiper';

import styles from './partnership-programmes.module.scss';

const PartnershipProgrammes: React.FC = () => {
  const { t, i18n } = useTranslation('main');
  const isDeutsch = i18n.language === 'de';

  return (
    <div className={styles.partnership}>
      <Typography
        className={clsx({ [styles.longLang]: isDeutsch })}
        variant='h1'
      >
        {t('Partnership programmes')}
      </Typography>

      <PartnershipSwiper />
    </div>
  );
};

export default PartnershipProgrammes;
