import React from 'react';

import type { SSRConfig } from 'next-i18next';
import i18nConfig from 'next-i18next.config';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import MainPageIndex from 'components/main-page/main-page-index';

export interface IMainPagePropsType {
  alias: string;
  comments_count: number;
  cover: string;
  created_at: string;
  description: string;
  id: string;
  meta_description: string;
  meta_title: string;
  tags: {
    id: string;
    name: string;
  }[];
  title: string;
}

export interface IMainPagePropsTypes extends SSRConfig {
  commentsCountList: { count: number; post_id: string }[];
  locale: string;
  postList: IMainPagePropsType[];
}

const MainPage: React.FC<IMainPagePropsTypes> = ({ locale }) => (
  <MainPageIndex locale={locale} />
);

export default MainPage;

export async function getStaticProps({ locale }) {
  const res = await fetch(
    `https://api-app.cryptomus.com/v1/blog/post/list?locale=${locale}`
  );

  const data = await res.json();

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'main'], i18nConfig)),
      locale,
      postList: data.result.items,
    },
  };
}
