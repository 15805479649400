import React from 'react';

import cn from 'clsx';
import { useMediaQuery } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import NewStatusIcon from 'icons/main/new-status-icon.svg';

import styles from './trading-badge.module.scss';

export type TTradingBadgePropsTypes = 'NEW' | 'ERROR' | 'Other';

interface ITradingBadgePropsTypes {
  customText?: string;
  status?: TTradingBadgePropsTypes;
}

const TradingBadge: React.FC<ITradingBadgePropsTypes> = ({
  status = 'NEW',
  customText,
}) => {
  const { t } = useTranslation('main');

  const isTablet = useMediaQuery('tablet');

  return (
    <div className={cn(styles.badge, styles[status])}>
      {status !== 'Other' && <NewStatusIcon />}

      {(!isTablet || status === 'Other') && (
        <span className={styles.text}>{customText || t(status)}</span>
      )}
    </div>
  );
};

export default TradingBadge;
