'use client';
import { useState } from 'react';
import Image from 'next/image';

import { MAIN_PAGE_STATISTICS, MAIN_PAGE_STORE_LINKS } from '../main-page.data';

import { getLocaleLink } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import DownloadAppButton from 'components/main-page/sign-up/download-app-button/download-app-button';

import QRCodeIcon from 'icons/main/qr-code-icon.svg';

import styles from './sign-up.module.scss';

interface ISignUpPropsTypes {
  locale: string;
}

const SignUp: React.FC<ISignUpPropsTypes> = ({ locale }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t } = useTranslation('main');

  const resultURL = getLocaleLink('https://app.cryptomus.com/overview', locale);

  return (
    <div className={styles.wrapper}>
      <div className={styles.preview}>
        <div className={styles.preview__left_block}>
          <h2>{t('One Account, Endless Possibilities')}</h2>
          <a
            className={styles.preview__left_block__sign_up}
            href={resultURL}
            rel='noreferrer'
            target='_blank'
          >
            {t('Sign Up')}
          </a>
          <div className={styles.preview__left_block__links}>
            {MAIN_PAGE_STORE_LINKS.map((item) => (
              <a key={item.href} href={item.href}>
                {item.image}
              </a>
            ))}

            <div
              className={styles.preview__left_block__links__icon}
              onClick={() => setIsOpen(!isOpen)}
            >
              <QRCodeIcon />
            </div>

            {isOpen && (
              <div className={styles.preview__left_block__links__qr}>
                <DownloadAppButton />
              </div>
            )}
          </div>
        </div>
        <div className={styles.preview__icon}>
          <Image
            alt='sign-up-right-block-image'
            height={402}
            src='/img/main/sign-up-right-block-image.png'
            width={644}
          />
        </div>
      </div>

      <div className={styles.statistic}>
        {MAIN_PAGE_STATISTICS.map((item) => (
          <div key={item.label} className={styles.statistic__item}>
            <h3>{item.count}</h3>
            <span>{t(item.label)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SignUp;
