import { useEffect, useState } from 'react';
import Link from 'next/link';

import { PARTNERSHIP_DATA } from '../../main-page.data';

import useMediaQuery from '@mui/material/useMediaQuery';
import { getLocaleLink } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';
import { Navigation } from 'swiper/modules';
import type { SwiperClass } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useSwiperNavigation } from 'hooks/use-swiper-navigation';

import IconArrowRight from 'icons/common/icon-arrow-right.svg';

import styles from './partnership-swiper.module.scss';

import 'swiper/css';

const PartnershipSwiper = () => {
  const { i18n, t } = useTranslation('main');
  const isDesktopL = useMediaQuery('(max-width:1899px)');
  const showSlideBtn = useMediaQuery('(max-width:1390px)');

  const { swiperRef, onSwiper, nextBtnRef, prevBtnRef } = useSwiperNavigation();
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const emulateOutlideClick = () => document.body.click();

  useEffect(() => {
    if (!isMounted) setIsMounted(true);
  }, [isMounted]);

  useEffect(() => {
    const swiper = swiperRef?.current as SwiperClass;

    swiper?.slideTo(0);

    if (isDesktopL) {
      swiper?.enable();
    } else {
      swiper?.disable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopL]);

  useEffect(() => {
    swiperRef.current?.update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className={styles.container}>
      <Swiper
        className={styles.swiper}
        dir={['fa', 'ar'].includes(i18n.language) ? 'rtl' : 'ltr'}
        grabCursor={false}
        modules={[Navigation]}
        slidesPerView='auto'
        breakpoints={{
          0: { spaceBetween: 16 },
          768: { spaceBetween: 24 },
        }}
        onBeforeInit={onSwiper}
        onResize={onSwiper}
        onSliderMove={emulateOutlideClick}
      >
        {PARTNERSHIP_DATA.map((slide) => (
          <SwiperSlide key={slide.title} className={styles.swiper__slide}>
            <img
              alt='slide'
              className={styles.slide__icon}
              src={slide.icon as string}
            />
            <div className={styles.slide__text}>
              <h5>{t(slide.title)}</h5>
              <span>{t(slide.description ?? '')}</span>
            </div>
            <Link
              type={slide.link.type || 'internal'}
              href={
                slide.link.type === 'external'
                  ? getLocaleLink(slide.link.href, i18n.language)
                  : slide.link.href
              }
            >
              {t('Go')}
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      {showSlideBtn ? (
        <div className={styles.buttons}>
          <button key={`${i18n.language}-left`} ref={prevBtnRef}>
            <IconArrowRight />
          </button>
          <button key={`${i18n.language}-right`} ref={nextBtnRef}>
            <IconArrowRight />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default PartnershipSwiper;
