import Link from 'next/link';
import { useRouter } from 'next/navigation';

import type { IOurProduct } from '../../main-page.data';

import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { getLocaleLink } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import TradingBadge from 'components/main-page/our-products/our-products-item/trading-badge/trading-badge';

import ArrowRight from 'icons/main/disabled-arrow-right-icon.svg';

import styles from './our-products-item.module.scss';

interface IOurProductsItem {
  data: IOurProduct;
  locale: string;
}

const OurProductsItem: React.FC<IOurProductsItem> = ({ data, locale }) => {
  const {
    row,
    title,
    description,
    link,
    type,
    status,
    icon,
    customBadgeText,
    mobileBackground,
    mobileBackgroundWidth,
    mobileBackgroundHeight,
    link_type,
    mobile_icon,
  } = data;

  const { t } = useTranslation('main');

  const router = useRouter();

  const isTablet = useMediaQuery('(max-width: 768px)');
  const isTabletProductVersion = !(isTablet && row > 1);

  const localizedLink =
    link_type === 'external' ? getLocaleLink(link, locale) : link;

  const handleBlockClick = () => {
    if (isTabletProductVersion) return;
    router.push(link);
  };

  const style = isTablet
    ? {
        backgroundImage: `url(${mobileBackground})`,
        backgroundSize: `${mobileBackgroundWidth}px ${mobileBackgroundHeight}px`,
      }
    : {};

  return (
    <div
      style={style}
      className={clsx(styles.product, {
        [styles.tablet_product]: !isTabletProductVersion,
      })}
      onClick={handleBlockClick}
    >
      <div className={styles.product__info}>
        <div className={styles.product__info__body}>
          {!isTabletProductVersion && (
            <div className={styles.mobile_icon_wrapper}>{mobile_icon}</div>
          )}
          <div
            className={clsx(styles.product__info__body__top, {
              [styles.tablet_top]: !isTabletProductVersion,
            })}
          >
            <h4>{t(title)}</h4>
            {type && isTabletProductVersion && (
              <span className={styles.product__info__body__top__type}>
                {t(type)}
              </span>
            )}
            {status && isTabletProductVersion && (
              <TradingBadge customText={customBadgeText} status={status} />
            )}
          </div>

          {isTabletProductVersion && <p>{t(description)}</p>}
        </div>

        {isTabletProductVersion && (
          <Link
            className={styles.learn_more_link}
            href={localizedLink}
            type={link_type || 'internal'}
          >
            {t('Learn more')}
            <ArrowRight />
          </Link>
        )}
      </div>

      {icon && !isTablet && <div>{icon}</div>}
    </div>
  );
};

export default OurProductsItem;
