import React from 'react';

import { getLocaleLink } from 'cryptomus-aurora-kit';
import { useTranslation } from 'next-i18next';

import TradingBadge from 'components/main-page/our-products/our-products-item/trading-badge/trading-badge';

import styles from './trading-banner.module.scss';

interface ITradingBannerPropsTypes {
  locale: string;
}

const TradingBanner: React.FC<ITradingBannerPropsTypes> = ({ locale }) => {
  const { t } = useTranslation('main');

  const resultURL = getLocaleLink('https://cryptomus.com/exchange', locale);

  return (
    <div className={styles.banner}>
      <div className={styles.banner_text}>
        <div className={styles.banner_text_top}>
          <h2>{t('Spot trading now available')}</h2>
          <TradingBadge />
        </div>

        <h6>
          {t(
            'Use flexible Cryptomus tools for an enjoyable trading experience'
          )}
        </h6>
      </div>

      <a className={styles.banner__link} href={resultURL}>
        {t('Go to Trade')}
      </a>
    </div>
  );
};

export default TradingBanner;
